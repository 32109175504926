import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'

import { AuthStateContext } from '../context/AuthContextProvider'

import FloofLogo from '../assets/floof_logo.svg'
import { LogoContainer } from './logo.module.scss'

export default ({ logoProps, className }) => {
  const { loggedIn } = useContext(AuthStateContext) || { loggedIn: false }
  const [logoUrl, setLogoUrl] = useState('/app/home')

  useEffect(() => {
    if (!loggedIn) {
      setLogoUrl('/')
    }
  }, [loggedIn])

  return (
    <div className={classNames(LogoContainer, className)}>
      <a href={logoUrl} {...logoProps}>
        <FloofLogo />
      </a>
    </div>
  )
}
